.activity-drawer-image {
  width: auto;
  height: auto;
  max-width: 160px;
  max-height: 160px;
  border-radius: 8px;
  margin-bottom: 16px;
  object-fit: cover;
}

.opening-hours {
  margin: 16px 0;

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 4px 0;
      display: flex;
      align-items: center;
    }
  }
}

.user-reviews {
  margin: 16px 0;

  .review {
    background-color: #f9f9f9;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.activity-drawer-actions {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  button {
    flex: 1;
    max-width: none;
    transition: transform 0.2s, background-color 0.2s;

    &:hover {
      transform: scale(1.02);
    }
  }
}

.close-button {
  display: block;
  margin: 0 auto;
  background-color: #e0e0e0;
  color: #424242;

  &:hover {
    background-color: #bdbdbd;
  }
}

.activity-drawer-paper {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  overflow-y: auto;

  .activity-drawer-container {
    padding: 24px;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;

    .image-loading-placeholder {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f5f5f5;
      border-radius: 8px;
      margin-bottom: 16px;
    }

    .activity-drawer-image {
      width: 100%;
      max-width: 300px;
      height: auto;
      object-fit: cover;
      margin: 0 auto 16px;
      display: block;
    }
  }

  @media (max-width: 1920px) {
    max-height: 90vh;

    .activity-drawer-container {
      padding: 16px;
      padding-bottom: calc(var(--bottom-nav-height) + 60px + env(safe-area-inset-bottom));

      .activity-drawer-image {
        max-width: 100%;
        max-height: 200px;
      }
    }

    .activity-drawer-actions {
      position: fixed;
      bottom: var(--mobile-bottom-spacing);
      left: 0;
      right: 0;
      padding: 4px;
      box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
      z-index: 1;

      button {
        width: 100%;
        margin: 4px 0;
      }
    }
  }

  // Improved scrollbar styling with better cross-browser support
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
