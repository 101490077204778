.map-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh - var(--header-height) - var(--bottom-nav-height));
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    height: calc(100vh - var(--header-height) - var(--mobile-bottom-spacing));
  }
}

.map-component {
  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &.loading {
    filter: grayscale(1);
    opacity: 0.7;
  }
}

.map-loading-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: rgba(255, 255, 255, 0.9);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.custom-marker {
  .marker-content {
    position: relative;
    width: 24px;
    height: 24px;
  }

  .marker-icon {
    position: relative;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    background: #ff00a6;
    border-radius: 50%;
    border: 2px solid #00FFFF;
    box-shadow: 0 0 12px rgba(0, 255, 255, 0.5);
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    filter: drop-shadow(0 0 4px rgba(0, 255, 255, 0.4));
  }

  .marker-pulse {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    background: rgba(0, 255, 255, 0.2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 2s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.4;
  }

  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 5px rgba(0, 255, 255, 0.3));
  }

  50% {
    filter: drop-shadow(0 0 8px rgba(0, 255, 255, 0.5));
  }

  100% {
    filter: drop-shadow(0 0 5px rgba(0, 255, 255, 0.3));
  }
}

.map-container {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: calc(100vh - 120px);
  /* Account for header and bottom nav */
  background: #121212;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.path-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.animated-path {
  fill: none;
  stroke-width: 2px;
  stroke-opacity: 0.4;
  filter: drop-shadow(0 0 5px rgba(0, 255, 255, 0.3));
  transition: all 0.3s ease;
  animation: glow 2s infinite ease-in-out;
  pointer-events: auto;
  cursor: pointer;

  &.active {
    stroke: #4d94ff;
    stroke-width: 3px;
    stroke-opacity: 0.8;
    filter: drop-shadow(0 0 5px rgba(77, 148, 255, 0.5));
  }

  &:not(.active) {
    stroke: #00ffff;
  }

  &:hover {
    stroke-opacity: 0.9;

    &.active {
      filter: drop-shadow(0 0 8px rgba(77, 148, 255, 0.7));
    }

    &:not(.active) {
      filter: drop-shadow(0 0 8px rgba(0, 255, 255, 0.5));
    }
  }
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  padding: 16px;
  border-radius: 8px;
  color: white;
}

.plan-details-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  padding: 16px;
  border-top: 1px solid rgba(0, 255, 255, 0.3);
  transition: bottom 0.3s ease;
  z-index: 2;

  &.hidden {
    bottom: -100%;
  }
}