.integration-paper {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  border-radius: 12px;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
}

.integration-logo {
  width: 40px;
  height: 40px;
}

@media (max-width: 600px) {
  .integration-logo {
    width: 30px;
    height: 30px;
  }
}

.integration-button {
  text-transform: none;
  border-radius: 24px;
}

.resy-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resy-modal-title {
  padding-top: 16px;
  display: flex;
  align-items: center;
}

.resy-card {
  background-color: #282c34;
  display: flex;
  width: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.resy-card-actions {
  flex-grow: 1;
}