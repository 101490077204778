.plan-builder {
  padding-top: 24px;
  padding-bottom: 24px;
  height: calc(100vh - var(--mobile-bottom-spacing));
  overflow-y: auto;

  .plan-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

    .MuiCardHeader-root {
      padding: 16px 20px;

      .MuiCardHeader-title {
        display: flex;
        align-items: center;
        gap: 12px;
        flex: 1;

        .MuiTypography-root {
          font-size: 1.25rem;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.87);
        }

        .MuiIconButton-root {
          color: rgba(0, 0, 0, 0.54);
          transition: all 0.2s ease;

          &:hover {
            color: rgba(0, 0, 0, 0.87);
            background: rgba(0, 0, 0, 0.04);
          }

          &.Mui-primary {
            color: #1976d2;

            &:hover {
              color: #1565c0;
              background: rgba(25, 118, 210, 0.04);
            }
          }
        }

        .MuiTextField-root {
          margin: 0;
          flex: 1;

          .MuiInput-root {

            &:before,
            &:after {
              border-color: rgba(0, 0, 0, 0.23);
            }

            &:hover:not(.Mui-disabled):before {
              border-color: rgba(0, 0, 0, 0.42);
            }

            &.Mui-focused:after {
              border-color: #1976d2;
            }
          }

          input {
            font-size: 1.25rem;
            font-weight: 500;
            padding: 8px 0;
            color: rgba(0, 0, 0, 0.87);

            &::placeholder {
              color: rgba(0, 0, 0, 0.42);
              opacity: 1;
            }
          }
        }
      }

      .MuiCardHeader-subheader {
        color: rgba(0, 0, 0, 0.6);
        font-size: 0.875rem;
        margin-top: 4px;
      }

      .MuiCardHeader-action {
        margin: 0;
        padding: 8px 0;
        align-self: center;

        .MuiButton-root {
          text-transform: none;
          font-weight: 500;
          min-width: 100px;

          &.MuiButton-outlined {
            border-color: rgba(25, 118, 210, 0.5);
            color: #1976d2;

            &:hover {
              background: rgba(25, 118, 210, 0.04);
              border-color: #1976d2;
            }

            .MuiSvgIcon-root {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

.activity-card {
  margin-bottom: 16px;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

  &.expanded {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  }

  .MuiCardHeader-root {
    padding: 16px 20px;
    background: rgba(0, 0, 0, 0.02);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    .MuiCardHeader-title {
      font-size: 1.1rem;
      font-weight: 500;
    }

    .MuiCardHeader-action {
      margin: 0;
      align-self: center;
    }
  }

  .activity-card-content {
    padding: 0;

    .photo-gallery-container {
      position: relative;
      height: 200px;
      overflow: hidden;

      .activity-card-photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .photo-navigation {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
        z-index: 2;

        button {
          background: rgba(255, 255, 255, 0.9);
          border-radius: 50%;
          padding: 8px;

          &:hover {
            background: rgba(255, 255, 255, 1);
          }
        }
      }
    }

    .activity-info-container {
      padding: 16px 20px;

      .time-chip {
        margin-bottom: 16px;

        .MuiChip-root {
          height: 32px;
          background: rgba(25, 118, 210, 0.08);
          border: none;

          .MuiChip-icon {
            color: #1976d2;
          }

          .MuiChip-label {
            font-weight: 500;
            padding-left: 8px;
          }
        }
      }

      .integration-buttons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
        margin-top: 16px;

        .MuiIconButton-root {
          width: 100%;
          height: 48px;
          border-radius: 8px;
          background: rgba(0, 0, 0, 0.04);
          transition: all 0.2s ease;
          position: relative;

          &:not(:disabled):hover {
            background: rgba(0, 0, 0, 0.08);
          }

          &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
          }

          img {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            transition: transform 0.2s ease;
          }

          &:hover img {
            transform: scale(1.1);
          }

          &.friends-button {
            color: #1976d2;

            .MuiBadge-badge {
              background: #1976d2;
              color: white;
              font-size: 12px;
              min-width: 20px;
              height: 20px;
            }
          }

          .MuiBadge-badge {
            &.MuiBadge-standard {
              transition: all 0.3s ease;
            }
          }

          // Integration status indicators
          .MuiCircularProgress-root {
            width: 16px !important;
            height: 16px !important;
          }

          .MuiSvgIcon-root {
            &.MuiSvgIcon-colorSuccess {
              color: #48ce39;
            }

            &.MuiSvgIcon-colorError {
              color: #d21313;
            }
          }
        }
      }
    }
  }

  .activity-card-details {
    padding: 20px;
    background: rgba(0, 0, 0, 0.02);
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    animation: cardExpand 0.3s ease-out;

    .MuiTextField-root {
      background: #fff;
      border-radius: 8px;
      transition: all 0.2s ease;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &:disabled {
        opacity: 0.7;
        background: rgba(0, 0, 0, 0.02);
      }

      &:not(:disabled):hover {
        background: rgba(255, 255, 255, 0.8);
      }
    }

    .action-buttons {
      margin-top: 20px;
      display: flex;
      gap: 12px;
      justify-content: flex-end;

      .MuiButton-root {
        min-width: 120px;
        transition: all 0.2s ease;

        &:hover {
          transform: translateY(-1px);
        }

        &.MuiButton-contained {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

          &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
  }

  .expand-more {
    transform: rotate(0deg);
    transition: transform 0.3s;

    &.expanded {
      transform: rotate(180deg);
    }
  }
}

// Animation for card expansion
@keyframes cardExpand {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.activity-card-details {
  animation: cardExpand 0.3s ease-out;
}

// Make the drag overlay have a higher z-index than the sticky header
.sortable-activity {
  &[data-dnd-dragging="true"] {
    z-index: 20;
    cursor: grabbing;

    .activity-card {
      opacity: 0.8;
      transform: scale(1.02);
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    }
  }

  .MuiIconButton-root {
    &[data-drag-handle] {
      cursor: grab;
      color: rgba(0, 0, 0, 0.54);
      transition: all 0.2s ease;

      &:hover {
        color: rgba(0, 0, 0, 0.87);
        background: rgba(0, 0, 0, 0.04);
      }

      &:active {
        cursor: grabbing;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}