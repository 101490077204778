.header-container {
  width: 100vw;
}

.header {
  width: 100vw;
  left: 0;
  right: 0;

  .header-title {
    flex-grow: 1;
  }
}

.app-logo {
  width: 40;
  flex-grow: 1;
}

.user-avatar {
  width: 40;
  height: 40;
  border: '2px solid white';
}