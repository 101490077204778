.activity-container {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: row;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  touch-action: none;

  .drag-handle {
    margin-right: 8px;
    cursor: grab;
    color: gray;

    &:hover {
      color: darkgray;
    }  
  }

  .delete-button-icon {
    color: #ff59ba;
  }

  .activity-details {
    max-width: 200px;
  }
  .button-group {
    display: flex;
  }  
}
