.container {
  margin-top: 32px;
  margin-bottom: 32px;
}

.card {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 32px;
  padding: 16px;
}

.center {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}