.photo-gallery {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 24px;

  .gallery-container {
    width: 100%;
    height: 300px;
    border-radius: 12px;
    background-color: #f5f5f5;
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease;
  }

  .gallery-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    &.prev {
      left: 8px;
    }

    &.next {
      right: 8px;
    }

    button {
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 50%;
      padding: 8px;

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }

      &:disabled {
        background-color: rgba(255, 255, 255, 0.5);
        cursor: not-allowed;
      }
    }
  }

  .photo-counter {
    position: absolute;
    bottom: 16px;
    right: 16px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    z-index: 2;
  }

  .photo-dots {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
    z-index: 2;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      transition: all 0.3s ease;

      &.active {
        background-color: white;
        transform: scale(1.2);
      }
    }
  }
}