.index-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

body.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;

  .MuiDrawer-paper {
    background-color: rgba(18, 18, 18, 0.95);
  }

  .itinerary-container {
    background-color: #2c2c2c;
  }
}

:root {
  --header-height: 64px;
  --bottom-nav-height: 56px;
  --mobile-bottom-spacing: calc(var(--bottom-nav-height) + env(safe-area-inset-bottom));
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f1f3f4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &-header {
    width: 100%;
    height: var(--header-height);
    overflow: hidden;
  }
}

button {
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.4);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}