.mini-button-icon {
  color: gray;
  font-size: 16px !important;

  &:hover {
    color: darkgray;
  }
}

.mini-button-icon-primary {
  color: #ff59ba;
  font-size: 16px !important;

  &:hover {
    color: darkgray;
  }
}
