.optionsContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 16px;
}

.paymentContainer {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 16px;
}

.errorMessage {
    color: #d32f2f;
    margin-top: 16px;
}

.dialogContent {
    padding: 24px;
}

.formActions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 16px;
}