.inspiration-container {
  width: 100%;
  height: calc(100vh - 56px);
  background-color: #000;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 56px;
  display: flex;
  flex-direction: column;
}

.reels-scroll-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
  padding-bottom: 56px;
}

.reel-card-wrapper {
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  user-select: none; // Prevent text selection during swipes
}

.reel-card {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: relative;
  border-radius: 0;
  overflow: hidden;

  .reel-images-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    touch-action: pan-x pan-y;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .reel-images-track {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
  }

  .reel-image {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none; // Prevent image dragging
  }
}

.carousel-dots {
  position: absolute;
  bottom: 220px; // Position above the content overlay and bottom nav
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 3;
  padding: 8px;

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s ease;

    &.active {
      background-color: #fff;
      transform: scale(1.2);
    }
  }
}

.content-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(transparent,
      rgba(0, 0, 0, 0.4) 20%,
      rgba(0, 0, 0, 0.8));
  color: white;
  z-index: 2;
}

.action-button {
  color: white !important;
  padding: 8px !important;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.reel-content {
  margin-top: 16px;
  text-align: left;

  .username {
    font-weight: 600;
    color: white;
    margin-bottom: 4px;
  }

  .caption {
    color: rgba(255, 255, 255, 0.8);
    display: -webkit-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@supports (-webkit-touch-callout: none) {
  .reels-scroll-container {
    height: calc(100vh - 56px - env(safe-area-inset-bottom));
  }

  .reel-card-wrapper {
    height: calc(100vh - 56px - env(safe-area-inset-bottom));
  }
}

// Constrain desktop
@media (min-width: 768px) {
  .reel-card {
    max-width: 400px;
    margin: 0 auto;
  }
}