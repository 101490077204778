.resy-booking-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.resy-booking-card {
    background-color: #ffffff;
    width: 400px;
    max-width: 90vw;
    position: relative;
    border-radius: 12px;
    overflow: hidden;

    .progress-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        overflow: hidden;

        .glowing-progress {
            height: 100%;
            background: linear-gradient(90deg, #4285F4, #34A853, #FBBC05, #EA4335);
            background-size: 400% 400%;
            animation: progress-glow 2s ease infinite;

            .MuiLinearProgress-bar {
                background: rgba(255, 255, 255, 0.2);
            }
        }
    }

    .time-picker {
        width: 100%;
        margin: 16px 0;
    }

    .reservations-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        gap: 8px;
        margin: 16px 0;

        .reservation-button {
            padding: 8px 16px;
            transition: all 0.2s ease;

            &:hover {
                transform: translateY(-2px);
            }
        }
    }

    .booking-complete {
        text-align: center;
        padding: 24px 0;

        .success-icon {
            font-size: 64px;
            margin-bottom: 16px;
            animation: scale-in 0.5s ease;
        }
    }

    .modal-actions {
        padding: 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
}

@keyframes progress-glow {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes scale-in {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}